import Carousel from '@elements/Carousel';
import Container from '@elements/Container';
import NextLink from '@elements/links/NextLink';

function RenameDocumentListTitle(name) {
  switch (name) {
    case 'bestsellers':
      return 'Meest verkocht';
    case 'most_read':
      return 'Meest bekeken';
    case 'most_recent':
      return 'Onlangs toegevoegd';
  }
}

export default function DocumentList(props) {
  // TODO apperently we have DocumentList below and for small we import Caroussel. However, Carousel is not headless.
  // In essence it's a document list with additional styles. This should be refactored.
  const { name, documents } = props.documentListData;

  let searchResultsUrl = '/';

  if (name === 'most_recent') {
    searchResultsUrl = '/search?sort=Date+desc';
  }

  return (
    <Container variant="white">
      <div className="relative pb-6 pt-12">
        <div className="flex justify-between pb-10">
          <h2 className="font-primary text-lg font-bold leading-6 text-primary-dark md:text-xl">
            {RenameDocumentListTitle(name)}
          </h2>
          {name === 'most_recent' && (
            <div className="right-0">
              <NextLink href={searchResultsUrl} linkType="primary">
                Alles bekijken
              </NextLink>
            </div>
          )}
        </div>
        <div>
          <Carousel documents={documents} />
        </div>
      </div>
    </Container>
  );
}
