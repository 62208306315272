import Container from '@elements/Container';
import Icon from '@elements/Icon';
import { performClientSearch } from '@providers/search';
import { buildSearchConfig } from '@search/utils/searchConfig';
import Link from 'next/link';
import { useEffect, useState } from 'react';

const LinkGrid = (props) => {
  const { categories } = props;

  function gridRows(categories, columns) {
    let gridRows = Math.ceil(categories.length / columns);
    return `repeat(${gridRows}, minmax(0, 1fr))`;
  }

  return (
    <div className="bg-white p-10 shadow-xl">
      <h1 className="mb-4 bg-white font-secondary text-lg font-bold leading-6 text-primary-dark md:text-xl">
        Waar kunnen we je mee helpen?
      </h1>
      {/* MD - */}
      <div className="flex flex-col gap-3 md:hidden">
        {categories.map((category, index) => (
          <Link
            key={index}
            href={category.href}
            className="flex place-items-center gap-3 hover:underline"
          >
            <Icon iconName="arrow-right" color="text-accent" />
            <div>{category.name}</div>
          </Link>
        ))}
      </div>
      {/* MD */}
      <div
        className="hidden grid-flow-col md:grid lg:hidden"
        style={{ gridTemplateRows: gridRows(categories, 2) }}
      >
        {categories.map((category, index) => (
          <Link
            key={index}
            href={category.href}
            className="flex place-items-center gap-3 py-1 hover:underline"
          >
            <Icon iconName="arrow-right" color="text-accent" />
            <div>{category.name}</div>
          </Link>
        ))}
      </div>
      {/* LG */}
      <div
        className="hidden grid-flow-col lg:grid"
        style={{ gridTemplateRows: gridRows(categories, 3) }}
      >
        {categories.map((category, index) => (
          <Link
            key={index}
            href={category.href}
            className="flex place-items-center gap-3 py-1 hover:underline"
          >
            <Icon iconName="arrow-right" color="text-accent" />
            <div>{category.name}</div>
          </Link>
        ))}
      </div>
    </div>
  );
};

const TileGrid = (props) => {
  const { categories } = props;
  return (
    <div className="mt-28 md:mt-24 lg:mt-12">
      <h2 className="mb-4 font-secondary text-lg font-bold leading-6 text-primary-dark md:text-xl">
        Waar kunnen we je mee helpen?
      </h2>
      <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-3 md:grid-cols-3 md:gap-4 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-6">
        {categories.map((category, index) => (
          <div key={index} className="box-border border border-white bg-white">
            <Link
              href={category.href}
              className="font-secondary text-base font-medium text-primary-dark hover:underline lg:text-lg"
            >
              <div className="flex place-items-center justify-between gap-4 bg-white p-4 shadow-lg hover:shadow-xl md:h-20 lg:h-24">
                {category.name}
                <Icon iconName="arrow-right" color="text-accent" />
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export async function getCategoryItems(field, category) {
  const decodedCategory = decodeURI(category);
  const query = {
    facets: [{ field, size: 99 }],
    size: 0,
  };
  if (category != null) {
    query.filters = [{ field: 'Category.keyword', value: decodedCategory }];
  }
  const body = buildSearchConfig(query);

  const response = await performClientSearch(body);

  if (response?.status === 200) {
    return {
      menuItems: response.data.aggregations,
    };
  } else {
    return {
      menuItems: [],
    };
  }
}

function Category({ tile, category }) {
  const [categoryItems, setCategoryItems] = useState([]);

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const field = category ? 'SubCategory.keyword' : 'Category.keyword';
        const data = await getCategoryItems(field, category);
        const buckets = Object?.values(data?.menuItems)[0]?.buckets || [];
        const categories = buckets.map(({ key }) => key);
        let categoryArray;
        if (category) {
          categoryArray = categories.map((subCategory, index) => ({
            id: index,
            name: subCategory,
            href: `/search?Category=${category}&SubCategory=${subCategory}`,
          }));
        } else {
          categoryArray = categories.map((category, index) => ({
            id: index,
            name: category,
            href: `/category/${encodeURIComponent(category.toLowerCase())}`,
          }));
        }
        setCategoryItems(categoryArray);
      } catch (e) {
        console.error(e);
      }
    };
    fetchCategoryData();
  }, [category]);

  const GridComponent = tile ? TileGrid : LinkGrid;

  return (
    <Container>
      <GridComponent categories={categoryItems} />
    </Container>
  );
}

export default Category;
